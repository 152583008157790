.shimmer-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background: #d5e2fb;
  border-radius: 20px;
  background-size: 2000px 100%;
}

.shimmer-animation {
  background-image: linear-gradient(
    to right,
    #d5e2fb 0%,
    #d0dffb 25%,
    #b9cff8 50%,
    #a2bff6 75%,
    #d5e2fb 100%
  );
  animation: shimmer 1.5s linear infinite forwards;
}

.shimmer-element {
  background: #d5e2fb;
  background-repeat: no-repeat;
  background-size: 1000px 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.shimmer-element.picture {
  height: 60px;
  width: 100%;
}

.shimmer-element.large {
  height: 20px;
  width: 100%;
}

.shimmer-element.medium {
  height: 15px;
  width: 60%;
}

.shimmer-element.small {
  height: 10px;
  width: 40%;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
