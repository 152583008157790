.product-detail-page-loader {
    background: #d5e2fb;
    background-image: linear-gradient(
      to right,
      #d5e2fb 0%,
      #d0dffb 25%,
      #b9cff8 50%,
      #a2bff6 75%,
      #d5e2fb 100%
    );
    background-size: 2000px 100%;
    animation: shimmer 1.5s linear infinite;
  }
  
  @keyframes shimmer {
    0% {
      background-position: -1000px 0;
    }
    100% {
      background-position: 1000px 0;
    }
  }
  